import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./Components/Home";
import Login from "./Components/Login";
import Nav from "./Components/Navbar";
import ViewVoter from "./Components/ViewVoter";
import Logs from "./Components/Logs";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Login />} />
        {/* <Route index element={<Home />} /> */}
        <Route path="/main" element={[<Nav />, <Home />]} />
        <Route path="/login" element={<Login />} />
        <Route path="/viewVoter" element={[<Nav />, <ViewVoter />]} />
        <Route path="/logs" element={[<Nav />, <Logs />]} />
        {/* <Route path="*" element={<NoPage />} /> */}
        {/* </Route> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
