import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { Modal, Button, FloatingLabel } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import LoginSvg from "../Images/login.svg";
import { getIp } from "./Vars";

const Login = () => {
  const navigate = useNavigate();
  const [userName, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async (event) => {
    event.preventDefault();
    fetch(
      getIp()+"/login?userName=" +
        userName +
        "&password=" +
        password
    )
      .then((response) => response.json())
      .then((data) => [sessionStorage.setItem('user', JSON.stringify(data)), navigate('/main')])
      .catch((error) => console.error("Error on handleSearch: " + error));
  };

  return (
    <>
      <Container className="py-5 poppins-regular">
        <div
          className="row justify-content-center align-items-center"
        >
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
            <div className="card">
              <div className="card-body p-5">
                <h1 className="mb-5 text-muted poppins-bold">Login to access</h1>
                <div className="row justify-content-center">
                  <div className="col-lg-12">
                    <form onSubmit={handleLogin}>
                      <div className="form-floating mb-4">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="user"
                          autoComplete="on"
                          value={userName}
                          onChange={(e) => setUsername(e.target.value)}
                          required
                        />
                        <label>Username</label>
                      </div>
                      <div className="form-floating">
                        <input
                          type="password"
                          className="form-control"
                          placeholder="password"
                          autoComplete="on"
                          required
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <label>Password</label>
                      </div>
                      <button
                        className="btn btn-dark btn-lg mt-4"
                        type="submit"
                      >
                        Login
                      </button>
                    </form>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Login;
