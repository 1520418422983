// globals.js
let ip = 'https://8e74-122-55-103-66.ngrok-free.app';

export const setIp = (newValue) => {
  ip = newValue;
};

export const getIp = () => {
  return ip;
};
