import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import "../App.css";
import { useNavigate } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import { getIp } from "./Vars";
const Logs = () => {
  const navigate = useNavigate();
  if (sessionStorage.length == 0) {
    navigate("/login");
  }
  const [usersData, setUsersData] = useState([]);

  const [event, setEvent] = useState([]);

  const [dateInWords, setDateInWords] = useState("");

  const [withFB, setWithFB] = useState(0);
  const [inactiveFB, setInactiveFB] = useState(0);
  const [noFB, setNoFB] = useState(0);
  const [lockedFB, setLockedFB] = useState(0);

  useEffect(() => {
  
      fetch(getIp()+"/logs")
        .then((response) => response.json())
        .then((data) => [
          setWithFB(data[0].totalfb),
          setNoFB(data[0].nofb),
          setInactiveFB(data[0].inactive),
          setLockedFB(data[0].locked),
        ])
        .catch((error) => console.error("Error on loading logs: " + error));

      fetch(getIp()+"/userlogs")
        .then((response) => response.json())
        .then((data) => [setUsersData(data)])
        .catch((error) => console.error("Error on loading logs: " + error));

      fetch(getIp()+"/events")
        .then((response) => response.json())
        .then((data) => [setEvent(data)])
        .catch((error) => console.error("Error on loading logs: " + error));

      const currentDate = new Date();
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const day = currentDate.getDate();
      const month = monthNames[currentDate.getMonth()];
      const year = currentDate.getFullYear();
      setDateInWords(`${month} ${day}, ${year}`);
    }, []);

  return (
    <>
      <Container className="py-5 poppins-regular">
        <Row className="align-items-center mt-5 mb-3">
          <Col lg={12}>
            <h1 className="poppins-bold">{dateInWords}</h1>
          </Col>
          <Col lg={3} sm={6} md={4}>
            <Card bg={"dark"} style={{ width: "18rem" }} className="mb-2 mx-2 my-2">
              <Card.Body>
                <Card.Title>With FB</Card.Title>
                <Card.Text
                  style={{ fontSize: "50px" }}
                  className="poppins-bold"
                >
                  {withFB}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} sm={6} md={4}>
            <Card bg={"dark"} style={{ width: "18rem" }} className="mb-2 mx-2 my-2">
              <Card.Body>
                <Card.Title>No FB</Card.Title>
                <Card.Text
                  style={{ fontSize: "50px" }}
                  className="poppins-bold"
                >
                  {noFB}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} sm={6} md={4}>
            <Card bg={"dark"} style={{ width: "18rem" }} className="mb-2 mx-2 my-2">
              <Card.Body>
                <Card.Title>Inactive FB</Card.Title>
                <Card.Text
                  style={{ fontSize: "50px" }}
                  className="poppins-bold"
                >
                  {inactiveFB}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} sm={6} md={4}>
            <Card bg={"dark"} style={{ width: "18rem" }} className="mb-2 mx-2 my-2">
              <Card.Body>
                <Card.Title>Locked FB</Card.Title>
                <Card.Text
                  style={{ fontSize: "50px" }}
                  className="poppins-bold"
                >
                  {lockedFB}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={8}>
            <Card bg={"dark"}>
              <Card.Header>User outputs</Card.Header>
              <Card.Body>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th className="text-center">#</th>
                      <th>User</th>
                      <th className="text-center">Output</th>
                    </tr>
                  </thead>
                  <tbody>
                    {usersData ? (
                      usersData.map((item, index) => (
                        <tr>
                          <td className="text-center">{index + 1}</td>
                          <td>{item.fname}</td>
                          <td className="text-center">{item.total}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={3}>No Data</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4}>
            <div className="table-responsive" style={{maxHeight: "420px", fontSize: "80%"}}>
              {event.map((item, index) => (
                <Alert key={index} variant={"dark"}>
                  <p className="poppins-book">{item.fname}</p>
                  <small className="poppins-regular">{item.event}</small>
                </Alert>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Logs;
