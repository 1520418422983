import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Button } from "react-bootstrap";
import NewType from "./GetLeaderType";
import VoterImage from "./GetImage";
import GetData from "./GetData";
import "../App.css";
import GetTags from "./GetTags";
import GetFb from "./GetFb";
import GetUser from "./GetUser";
import { useNavigate } from "react-router-dom";
import GetLeader from "./GetLeader";
import GetWarded from "./GetWarded";
import Spinner from "react-bootstrap/Spinner";
import { getIp } from "./Vars";
const Home = () => {
  const navigate = useNavigate();
  if (sessionStorage.length == 0) {
    navigate("/login");
  }

  const userData = sessionStorage.getItem("user");

  // Parse the stored data as JSON
  const parsedUserData = JSON.parse(userData);

  // Access the value of user_id
  const userId = parsedUserData.user_id;

  const [voters, setVoters] = useState([]);
  const [isPending, setisPending] = useState(false);

  //add useeffect for disabling searchbutton
  //add stop button to stop searching

  const handleSpanClick = (id) => {
    console.log(id);
    const win = window.open("/viewVoter?id=" + id + "", "_blank");
    win.focus();
  };

  const handleHater = (id, tag) => {
    fetch(
      getIp()+"/addTag?id=" +
        id +
        "&tag=" +
        tag +
        "&uid=" +
        userId +
        "",
      {
        method: "POST",
      }
    );
    alert("Tag saved.");
  };

  const Search = () => {
    const [searchTxt, setSearchTxt] = useState("");

    const handleSubmit = (event) => {
      if (!searchTxt) {
        alert("Input name to search...");
      } else {
        event.preventDefault();
        setisPending(true);
        fetch(getIp()+"/searchVoter?searchTxt=" + searchTxt + "")
          .then((response) => response.json())
          .then((data) => [
            setVoters(data),
            setisPending(false),
            console.log(data),
          ])
          .catch((error) => console.error("Error on handleSearch: " + error));
      }
    };

    return (
      <>
        <h1 className="poppins-bold mt-5">
          Search {sessionStorage.getItem("userid")}
        </h1>
        <Form onSubmit={handleSubmit}>
          <InputGroup>
            <Form.Control
              placeholder="Juan Dela Cruz, Barangay, Municipality"
              aria-label="Juan Dela Cruz, Barangay, Municipality"
              aria-describedby="basic-addon2"
              className="searchInput"
              id="searchTxt"
              autoComplete="on"
              onChange={(e) => setSearchTxt(e.target.value)}
              value={searchTxt}
              required
            />
            <Button
              variant="outline-default"
              id="button-addon2"
              type="submit"
              {...(isPending && "disabled")}
            >
              <i className="bi bi-search"></i>
            </Button>
          </InputGroup>
        </Form>
      </>
    );
  };

  return (
    <>
      <Container className="py-5 poppins-regular">
        <Search />
        {!isPending ? (
          voters ? (
            <ul className="names mt-5">
              {voters.map((item, key) => (
                <li className="names-list">
                  <Row className="align-items-left">
                    <Col lg={2}>
                      <VoterImage id={item.v_id} picwidth={80} picheight={80} />
                    </Col>
                    <Col lg={10}>
                      <h5
                        className="poppins-bold nameLink"
                        onClick={() => handleSpanClick(item.v_id)}
                      >
                        <GetData type="name" id={item.v_id} />{" "}
                        <small>
                          <GetFb type="badge" id={item.v_id} />
                        </small>
                      </h5>
                      <div className="details">
                        <GetData type="address" id={item.v_id} />{" "}
                        <GetData type="precinct" id={item.v_id} />
                        <br />
                        <GetData type="bday" id={item.v_id} />{" "}
                        <strong>
                          <GetData type="age" id={item.v_id} />
                        </strong>
                        <GetFb type="link" id={item.v_id} />
                        <br />
                        <GetLeader id={item.v_id} />
                        <GetWarded id={item.v_id} className="text-muted" />
                        <div className="text-muted remarks poppins-light">
                          <GetTags id={item.v_id} />
                        </div>
                        {/* <Button
                          onClick={() => handleHater(item.v_id, "605")}
                          className="btn btn-dark"
                          size="sm"
                        >
                          Mining Hater
                        </Button>{" "}
                        <Button
                          onClick={() => handleHater(item.v_id, "606")}
                          className="btn btn-dark"
                          size="sm"
                        >
                          ASANZA
                        </Button> */}
                      </div>
                    </Col>
                  </Row>
                </li>
              ))}
            </ul>
          ) : (
            <></>
          )
        ) : (
          <div className="mt-3">
            <Spinner animation="grow" />
          </div>
        )}
      </Container>
    </>
  );
};

export default Home;
